<template>
  <ConfirmDeleteOverlayStyled>
    <Modal :errors="errors">
      <template v-slot:header>
        {{ $t('titles.pleaseConfirm') }}
      </template>
      <template v-slot:main>
        <div v-html="confirmText" />
      </template>
      <template v-slot:footer>
        <ActionsStyled>
          <ButtonSolidStyled @click="$emit('close')" :disabled="isConfirming">
            {{ $t('actions.cancel') }}
          </ButtonSolidStyled>
          <ButtonBorderedStyled @click="confirm" :disabled="isConfirming">
            <template v-if="!isConfirming">
              {{ $t('actions.ok') }}
            </template>
            <LoadingDots v-else />
          </ButtonBorderedStyled>
        </ActionsStyled>
      </template>
    </Modal>
  </ConfirmDeleteOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { nanoid } from 'nanoid'

import { DateTime } from 'luxon'

import { ButtonBordered as ButtonBorderedStyled, ButtonSolid as ButtonSolidStyled } from '@styles/buttons'

import { Modal, LoadingDots } from '@common/components'

const ConfirmDeleteOverlayStyled = styled('div')`
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  padding-top: 50px;
`

const ActionsStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
`

export default {
  props: {
    args: {
      type: Object,
    },
  },
  components: {
    ConfirmDeleteOverlayStyled,
    ActionsStyled,
    ButtonSolidStyled,
    ButtonBorderedStyled,
    LoadingDots,
    Modal,
  },
  computed: {
    confirmText() {
      return this.args?.confirmText || 'Are you sure?'
    },
  },
  data() {
    return {
      errors: [],
      isConfirming: false,
    }
  },
  methods: {
    async confirm() {
      this.isConfirming = true
      try {
        const res = await this.args.onConfirm(this.args.onConfirmArgs)
        if (res) {
          this.$emit('close')
        }
      } catch (err) {
        const error = {
          id: nanoid(),
          rawError: err,
          time: DateTime.local(),
        }
        this.errors.push(error)
      }
      this.isConfirming = false
    },
  },
}
</script>
